@import '~bootstrap/scss/bootstrap';

//Fonts
@font-face {
  font-family: 'proza_libreregular';
  src: url('public/fonts/Proza_Libre/prozalibre-regular-webfont.woff2') format('woff2'),
       url('public/fonts/Proza_Libre/prozalibre-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


html * {
  font-family: proza_libreregular;
}

p {
  font-size: 1.1rem;
}


$back-color-light: #FFEDE3;
$back-color-normal: #FFE3CB;
$primary-color: #9C4434;
$secondary-color: #C25241;
$text-color-light: #FFEDE3;
$text-color-dark: #434556;

html, body, #root {
  height: 100%;
}

body {
  background-color: $back-color-normal;
}

#root {
  display: flex;
  flex-direction: column;
 }

// Prevent Navbar to hide content
.Under-navbar { padding-top: 70px; }

.Standard-vertical-padding {
  padding-top: 70px;
  padding-bottom: 70px;
}

.Multiline-text {
  white-space: pre-line;
}

.Text-centered {
  text-align: center;
}

.Centered-vertically {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

p {
  color: $text-color-dark
}

.Background-light {
  background-color: $back-color-light;
  transition-property: background-color;

  transition-duration: 1s;
  transition-timing-function: linear;
}

.Background-normal {
  background-color: $back-color-normal;
  transition-property: background-color;

  transition-duration: 1s;
  transition-timing-function: linear;
}

.Title-primary {
  color: $primary-color;
}

.Title-secondary {
  color: $secondary-color;
}

.Text-light {
  color: $text-color-light;
}

.Title-dark {
  color: $text-color-dark;
}

.Background-primary {
  background-color: $primary-color;
}

.Button-primary {
  @extend .Background-primary
}

.Button-primary:hover {
  background-color: $secondary-color;
  color: $text-color-light;
}

.Button-primary:active, .Button-primary:focus, .Button-primary:visited {
  background-color: $secondary-color;
  color: $text-color-light;
}

.Background-secondary {
  background-color: $secondary-color;
}

.Background-dark {
  background-color: $text-color-dark;
}

.Logo-list {
  margin: 0.5rem;
  color: $primary-color;
}

.Logo-list-light {
  @extend .Logo-list;
  color: $back-color-light
}

.Logo-list-secondary {
  @extend .Logo-list;
  color: $secondary-color
}

.App {
  text-align: center;
}

.Centered-componant {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.Not-found-header {
  // background-color: $back-color-normal;  
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  color: white;
}

.Not-found-header::before{
  content: "";
  position: absolute;
  background-image: url("public/images/404.png");
  background-size: cover;
  background-position: center;
  top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
}


.App-header {
  // background-color: $back-color-normal;  
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  color: white;
}

@media screen and (min-width: 720px){
.App-header::before{
  content: "";
  position: absolute;
  background-image: url("public/images/Accueil/accueil.png");
  background-repeat: no-repeat;
  opacity: 0.6;
  background-position: center;
  top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
}
}

@media screen and (max-width: 720px){
  .App-header::before{
    content: "";
    position: absolute;
    opacity: 0.6;
    top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
  }  
}

.Welcome-content {
  position: relative;
  opacity: 1;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.Menu-link:hover {
  text-decoration: underline;
}

section {
  @extend .Standard-vertical-padding; 
  @extend .Text-centered;
}

//Realisations

.Realisation-container {
  position: relative;
  background-color: $primary-color;
}



.Realisation-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.Realisation-overlay {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.Realisation-short {
  color: $text-color-light;

  // background-color: #9C4434;
  padding: 1rem;
  border-radius: 15px;

}

.Realisation-text {
  margin: 0;
  font-size: 1.6rem;
}

.Realisation-container:hover .Realisation-image {
  opacity: 0.3;
}

.Realisation-container:hover .Realisation-overlay {
  opacity: 1;
}

// Contact form
.Contact-textarea {
  height: 20rem;
  max-height: 20rem;
  overflow: scroll;
  resize: none;
}

.Custom-form {
  text-align: left;
}

.Content {
  flex: 1 0 auto;
}

.Footer {
  margin-top: auto;
}

.Slideshow {
  overflow: hidden;
  width: 100%;
}

.Slideshow-slider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.Slide {
  display: inline-block;
  width: 100%;
}

.Arrow {
  border: solid $primary-color;
  width: 75px;
  height: 75px;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: -100% 50%;
}

.Primary-text {
  color: $primary-color;
}

a {
  text-decoration: underline;
  color: $primary-color;
}

a:hover {
  text-decoration: none;
}

.Arrow-small {
  border: solid $primary-color;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.Arrow:hover {
  cursor: pointer;
  border-width: 0 7px 7px 0;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.Slide-image {
  width: inherit;
}

.Slideshow-dots {
  text-align: center;
}

.Slideshow-dot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: $text-color-dark;
}

.Slideshow-dot.active {
  background-color: $primary-color;
}

//Youtube embeded video
.Video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.Video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}



.myCarousel {
  position: relative;

  .carousel-indicators {
    position: absolute;
    bottom: -40px; /* Adjust as needed */
    z-index: 1;
    display: flex; /* Ensure indicators are aligned properly */
    justify-content: center;

    li {
      background-color: #000; /* Custom color */
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    background: none;
    z-index: 1;
  }

  .carousel-control-prev {
    left: -50px; /* Adjust as needed */
  }

  .carousel-control-next {
    right: -50px; /* Adjust as needed */
  }
}

.quote {
  text-align: left;
  border-left: 2px solid $secondary-color;
  padding-left: 1rem;
}
